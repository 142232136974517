import axios from 'axios'

const getBasePath = () => {
  if (typeof window !== `undefined`) {
    if (
      window.location.host.includes('staging') ||
      window.location.host.includes('localhost')
    ) {
      return 'https://staging.api.uask.io'
    }
    return 'https://api.uask.io'
  }
}

export const requestForm = async props => {
  await axios.post(getBasePath() + '/dashboard/report-download-request', props)
}
