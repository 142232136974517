import BaseContainer from '@components/base-container'
import ReportCard from '@components/report-card'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import ReportForm from '@screens/reports/form'
import { format, parseISO } from 'date-fns'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { Breadcrumb, Button, Col, Container, Modal, Row } from 'react-bootstrap'

const colorSet = {
  bgColor: '#F9F9F9',
  irregular: '#6A778E',
  prominent: '#1E2D4F'
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
  }
}
const ReportSinglePage = props => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const onComplete = () => {}

  const { title, id, content, coverImage, categories, createdAt, date } =
    props.pageContext.node

  const relatedCards = props.pageContext.related
    .map(({ node }) => node)
    .filter(node => node.id !== id)
    .slice(0, 3)

  return (
    <BaseContainer>
      <section style={{ backgroundColor: colorSet.bgColor }} className="py-5">
        <Container className="pt-lg-5 pt-4">
          <Row>
            <Col lg={4} className="half-pill-img-wrapper">
              <div className="half-pill-img">
                <GatsbyImage
                  image={getImage(coverImage)}
                  alt={title}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    backgroundPosition: 'center'
                  }}
                />
              </div>
            </Col>

            <Col lg={8} md={12}>
              <Breadcrumb className="mb-1 px-2">
                <Breadcrumb.Item>
                  <Link to="/reports">Reports</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
              </Breadcrumb>

              <Row className="bg-white float-left-row">
                <Col lg={10} md={12}>
                  {categories && (
                    <h6 className={'mb-3 fw-light category-text'}>
                      {categories[0]?.name?.toUpperCase()}
                    </h6>
                  )}
                  <h1 className="mb-3" style={{ color: colorSet.prominent }}>
                    <strong>{title}</strong>
                  </h1>
                  <h5 className="mb-0 paragraph">
                    {format(parseISO(date || createdAt), 'MMM dd,' + ' yyyy')}
                  </h5>
                  <div className="d-lg-none d-flex justify-content-center mt-3 ">
                    <GatsbyImage
                      image={getImage(coverImage)}
                      alt={title}
                      style={{
                        width: '100%',
                        height: 300,
                        objectFit: 'contain',
                        backgroundPosition: 'center'
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="document-row">
                <Col lg={9} md={12}>
                  {documentToReactComponents(JSON.parse(content.raw), options)}
                </Col>
                <div>
                  <Button className="text-white mt-3" onClick={handleShow}>
                    Download Report
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={{ span: 12 }}>
              <h5>Related Content</h5>
            </Col>
            {relatedCards.map(node => (
              <Col xl={3} lg={4} md={4} sm={6} xs={12} className={'py-2'}>
                <ReportCard
                  titleLineClamp={3}
                  titleFontSize={'1.25rem'}
                  category={`REPORT |
       ${format(
         parseISO(node.date || node.createdAt),
         'MMM dd, yyyy'
       ).toUpperCase()}`}
                  key={node.id}
                  data={node}
                  onClick={() => {}}
                  location={'/reports/'}
                  isRelated
                />
              </Col>
            ))}
          </Row>
        </Container>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <h5 className="mb-0">Download Report</h5>
          </Modal.Header>
          <Modal.Body>
            <ReportForm reportName={title} onComplete={onComplete} />
          </Modal.Body>
        </Modal>
      </section>
    </BaseContainer>
  )
}
export default ReportSinglePage
