import { requestForm } from '@services/api'
import React, { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'

import { useForm } from 'react-hook-form'

const ReportForm = ({ reportName, onComplete }) => {
  const { handleSubmit, register } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState<string>(null)

  const onSubmit = async ({ firstName, lastName, companyName, email }) => {
    setIsLoading(true)
    if (firstName && lastName && companyName && email) {
      await requestForm({ firstName, lastName, companyName, email, reportName })
      setText(`Success! We'll send you the report soon!`)
      onComplete()
    }
    setIsLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="First Name"
          {...register('firstName')}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Last Name"
          {...register('lastName')}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control
          type="text"
          placeholder="Company Name"
          {...register('companyName')}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Control type="text" placeholder="Email" {...register('email')} />
      </Form.Group>

      <div className={'justify-content-center d-flex'}>
        {text ? (
          <Alert variant={'success'} className={'w-100'}>
            {text}
          </Alert>
        ) : (
          <Button
            variant="primary"
            type="submit"
            className={'rounded text-white align-self-end'}
            disabled={isLoading}
          >
            {isLoading ? 'Loading…' : 'Download'}
          </Button>
        )}
      </div>
    </Form>
  )
}

export default ReportForm
